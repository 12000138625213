(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:discussionCard
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <discussion-card></discussion-card>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('discussionCard', discussionCard);

  function discussionCard() {
    return {
      restrict: 'EA',
      scope: {
        groupByItems: '='
      },
      templateUrl: 'components/discussion-card/discussion-card-directive.tpl.html',
      bindToController: {
        discussions: '=',
        group: '='
      },
      controllerAs: 'discussionCard',
      controller: 'DiscussionCardCtrl'
    };
  }
}());
